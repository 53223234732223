define("discourse/plugins/discourse-ratings/discourse/templates/components/topic-rating-tip", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a role="button" {{on "click" (action "toggleDetails")}}>
    {{d-icon "info-circle"}}
  </a>
  {{#if showDetails}}
    <div class="tip-details">
      {{html-safe (i18n details)}}
    </div>
  {{/if}}
  */
  {
    "id": "dTykakNM",
    "block": "[[[11,3],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0],\"toggleDetails\"],null]],null],[12],[1,\"\\n  \"],[1,[28,[35,2],[\"info-circle\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"showDetails\"]],[[[1,\"  \"],[10,0],[14,0,\"tip-details\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[[28,[37,5],[[30,0,[\"details\"]]],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `showDetails` property path was used in the `discourse/plugins/discourse-ratings/discourse/templates/components/topic-rating-tip.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showDetails}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `details` property path was used in the `discourse/plugins/discourse-ratings/discourse/templates/components/topic-rating-tip.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.details}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"on\",\"action\",\"d-icon\",\"if\",\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/templates/components/topic-rating-tip.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});