define("discourse/plugins/discourse-ratings/discourse/helpers/rating-list", ["exports", "discourse-common/lib/helpers", "discourse/plugins/discourse-ratings/discourse/lib/rating-utilities", "handlebars"], function (_exports, _helpers, _ratingUtilities, _handlebars) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _ratingList;
  (0, _helpers.registerRawHelper)("rating-list", _ratingList);
  function _ratingList(ratings) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return new _handlebars.default.SafeString((0, _ratingUtilities.ratingListHtml)(ratings, opts));
  }
  ;
});