define("discourse/plugins/discourse-ratings/discourse/components/rating-type", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const minTypeLength = 2;
  const minNameLength = 2;
  const noneType = "none";
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "tr",
    classNameBindings: [":rating-type", ":admin-ratings-list-object", "hasError"],
    invalidType: (0, _computed.lt)("type.type.length", minTypeLength),
    invalidName: (0, _computed.lt)("type.name.length", minNameLength),
    addDisabled: (0, _computed.or)("invalidType", "invalidName"),
    noneType: (0, _computed.equal)("type.type", noneType),
    showControls: (0, _computed.not)("noneType"),
    didReceiveAttrs() {
      this.set("currentName", this.type.name);
    },
    updateDisabled(invalidName, name, currentName) {
      return invalidName || name === currentName;
    }
  }, [["method", "updateDisabled", [(0, _decorators.default)("invalidName", "type.name", "currentName")]]]));
});