define("discourse/plugins/discourse-ratings/discourse/components/rating-actions", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: "ratings-action-controls"
  });
});