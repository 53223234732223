define("discourse/plugins/discourse-ratings/discourse/templates/connectors/composer-fields/composer-controls-rating", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if model.showRatings}}
    {{#each model.ratings as |rating|}}
      {{select-rating rating=rating updateRating=(action "updateRating")}}
    {{/each}}
  {{/if}}
  */
  {
    "id": "CXxdUoR6",
    "block": "[[[41,[30,0,[\"model\",\"showRatings\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\",\"ratings\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,3],null,[[\"rating\",\"updateRating\"],[[30,1],[28,[37,4],[[30,0],\"updateRating\"],null]]]]],[1,\"\\n\"]],[1]],null]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-ratings/discourse/templates/connectors/composer-fields/composer-controls-rating.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-ratings/discourse/templates/connectors/composer-fields/composer-controls-rating.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"rating\"],false,[\"if\",\"each\",\"-track-array\",\"select-rating\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/templates/connectors/composer-fields/composer-controls-rating.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});