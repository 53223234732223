define("discourse/plugins/discourse-ratings/discourse/widgets/rating-star", ["exports", "discourse/widgets/widget"], function (_exports, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("rating-star", {
    tagName: "input",
    buildAttributes(attrs) {
      let result = {
        type: "radio",
        value: attrs.value
      };
      if (attrs.checked) {
        result["checked"] = true;
      }
      if (attrs.disabled) {
        result["disabled"] = true;
      }
      return result;
    },
    html() {
      return;
    }
  });
});