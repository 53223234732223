define("discourse/plugins/discourse-ratings/discourse/components/rating-type-chooser", ["exports", "select-kit/components/multi-select"], function (_exports, _multiSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _multiSelect.default.extend({
    classNames: ["rating-type-chooser"],
    selectKitOptions: {
      filterable: true
    },
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.types) {
        this.set("value", this.types.split("|"));
      }
    },
    onChange(value) {
      this.set("types", value.join("|"));
    }
  });
});