define("discourse/plugins/discourse-ratings/discourse/routes/admin-plugins-ratings", ["exports", "discourse/routes/discourse", "@ember/array", "rsvp", "discourse/plugins/discourse-ratings/discourse/models/rating-type", "discourse/plugins/discourse-ratings/discourse/models/rating-object", "I18n"], function (_exports, _discourse, _array, _rsvp, _ratingType, _ratingObject, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const noneType = "none";
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _ratingType.default.all();
    },
    afterModel() {
      return (0, _rsvp.all)([this._typesFor("category"), this._typesFor("tag")]);
    },
    setupController(controller, model) {
      let ratingTypes = model || [];
      ratingTypes.unshift({
        type: noneType,
        name: _I18n.default.t("admin.ratings.type.none_type"),
        isNone: true
      });
      controller.setProperties({
        ratingTypes: (0, _array.A)(ratingTypes.map(t => _ratingType.default.create(t))),
        categoryTypes: (0, _array.A)(this.categoryTypes),
        tagTypes: (0, _array.A)(this.tagTypes)
      });
    },
    _typesFor(object) {
      return _ratingObject.default.all(object).then(result => {
        this.set(`${object}Types`, result);
      });
    },
    actions: {
      refresh() {
        this.refresh();
      }
    }
  });
});